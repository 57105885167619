import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styles from './posts.module.css'

export default (props) => {

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 3) {
          totalCount
          edges{
            node {
              id
              excerpt
              frontmatter {
                date(formatString: "MMMM D, YYYY")
                title
              }
              fields {
                slug
              }
              html
              timeToRead
            }
          }
        }
      }
    `
  )

  return(
    <>
      {allMarkdownRemark.edges.map(({ node }, index) => (
        <Link to={node.fields.slug} key={index} className={styles.post}>
          <h3>{node.frontmatter.title}</h3>
          <p>{node.excerpt}</p>
          <div>
            <em>{node.frontmatter.date}</em>
            <em>{node.timeToRead} minute{node.timeToRead > 1 && `s`} to read</em>
          </div>
        </Link>
      ))}
      {allMarkdownRemark.totalCount > 3 && (
      <Link to={`/archive/`}>All posts</Link>
      )}
    </>
  )
}