import React from 'react'
import SEO from '../components/seo'
import Posts from '../components/posts'

export default () => (
  <>
    <SEO />
    <h2>Welcome</h2>
    <p>You know what? It is beets. I've crashed into a beet truck. Yeah, but John, if The Pirates of the Caribbean breaks down, the pirates don’t eat the tourists. Must go faster... go, go, go, go, go! You're a very talented young man, with your own clever thoughts and ideas. Do you need a manager?</p>
    <Posts />
  </>
)